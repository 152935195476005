import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import ProjectDetails from "../../images/projects/BF.yml"

const BloomfieldResort = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query(
      $floorPlan: String = "/images/projects/bloomfield-resort/04 FLOOR PLANS/"
    ) {
      logo: file(relativePath: { eq: "bloomfield-resort-logo.png" }) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: { eq: "projects/bloomfield-resort/BF Resort SDP.png" }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "TownhouseUnits", num: "248" },
    { metric: "TypesofUnits", num: "3" },
    { metric: "TypicalLotArea", num: "38 - 72" },
    { metric: "TypicalFloorArea", num: "36 - 45" },
  ])
  const props: LocationTemplateProps = {
    title: "Bloomfield Resort",
    logo,
    location: "Brgy. Bical, Mabalacat City, Pampanga",
    metrics,
    carouselImgs: [
      require("../../images/projects/bloomfield-resort/01 BF Resort Entrance.jpg"),
      require("../../images/projects/bloomfield-resort/02 BF Resort End Unit.png"),
      require("../../images/projects/bloomfield-resort/03 BF Resort Shophouse.png"),
      require("../../images/projects/bloomfield-resort/04 BF Single Attached.png"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Bloomfield Resort is the third phase to the Bloomfield Development, following Bloomfield and Bloomfield Park respectively. What sets it apart from the previous phases is its proximity to an existing creek which set the concept for this project. \n Taking its natural surrounding into consideration for its concept, Bloomfield Resort mimics natural scapes by incorporating greeneries into its development plan.",
    },
    floorPlans: {
      description: `Bloomfield Resort has three (3) types of units to offer. (1) Townhouse A - stands at two-storeys with a typical lot area ranging from 38-51sq.m; (2) Single Attached - a bungalow type unit with a typical lot area of 70sq.m; and (3) Townhouse C - a two-storey unit equipped with a store space with a typical lot area ranging from 38-52sq.m.

      Our units give you the freedom and the power to create your home the way you want to. ** All Floor Plans below are for suggested layout only.`,
      items: [
        [
          {
            name: "Townhouse A End Unit",
            fpImg: fpImg("BF RESORT THA END FP"),
            pImg: fpImg("BF RESORT THA END PERSP"),
          },
          {
            name: "Townhouse A Inner Unit",
            fpImg: fpImg("BF RESORT THA INNER FP"),
            pImg: fpImg("BF RESORT THA INNER PERSP"),
          },
          {
            name: "Single Attached",
            fpImg: fpImg("BF RESORT SA FP"),
            pImg: fpImg("BF RESORT SA PERSP"),
          },
          {
            name: "Townhouse C End Unit",
            fpImg: fpImg("BF RESORT THC END FP"),
            pImg: fpImg("BF RESORT THC PERSP"),
          },
          {
            name: "Townhouse C Inner Unit",
            fpImg: fpImg("BF RESORT THC INNER FP"),
            pImg: fpImg("BF RESORT THC PERSP"),
          },
        ],
      ],
    },
    modelUnits: [
      require("../../images/projects/bloomfield-resort/05 MODEL UNIT/01 BF RESORT FOYER_LIVING_DINING.jpg"),
      require("../../images/projects/bloomfield-resort/05 MODEL UNIT/02 BF RESORT T_B.jpg"),
      require("../../images/projects/bloomfield-resort/05 MODEL UNIT/03 BF RESORT STAIRS.jpg"),
      require("../../images/projects/bloomfield-resort/05 MODEL UNIT/04 BF RESORT BEDROOM1.jpg"),
      require("../../images/projects/bloomfield-resort/05 MODEL UNIT/05 BF RESORT MASTER BEDROOM.jpg"),
    ],
    projDetails: {
      ...ProjectDetails,
      name: "Bloomfield Resort",
      description:
        `Conceptualized as an “agri-resort” type of development, Bloomfield Resort is a rest house constructed within open space and surrounded by landscaped greeneries. Mimicking a natural landscape, BF Resort features various fruit-bearing trees, picnic areas, flower gardens, and a mini pond.`
    }
  }
  return <Template {...props} />
}

export default BloomfieldResort
